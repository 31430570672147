import { Badge, BadgeGroup, ButtonGroup, Col, Container, ImageGallery, PageBanner, Row, Text, Title } from '@zigurous/react-components'; // prettier-ignore
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { CallToActionButton, CallToActionModal, DetailsList, Page } from '../../components'; // prettier-ignore
import { trackEvent } from '../../firebase/analytics';
import type { AssetData } from '../../types';

interface AssetProps {
  data: { asset: AssetData };
  location: Location;
}

export default function Asset({ data, location }: AssetProps) {
  const [showModal, setShowModal] = useState(false);
  const { asset } = data;
  const metadata = {
    url: `https://zigurous.com/assets/${asset.id}`,
    title: `Zigurous • ${asset.title}`,
    description: asset.description,
    image: asset.image.sharp.original.src,
  };
  return (
    <Page className="asset" location={location} metadata={metadata}>
      <CallToActionModal
        open={showModal}
        onButtonClick={button => {
          trackEvent('modal_button', {
            content_type: 'button',
            event_category: 'engagement',
            event_label: button.label,
            item_id: asset.id,
          });
        }}
        onRequestClose={() => setShowModal(false)}
        socialButtons={[
          { label: 'Follow Us', link: 'twitter' },
          { label: 'Patreon', link: 'patreon' },
        ]}
        title="Thank You"
      >
        We appreciate your support of our assets. If you like our work consider
        becoming a patron so we can continue developing more assets like this
        one.
      </CallToActionModal>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg={4}>
              <Title subtitle size={5}>
                {asset.subtitle}
              </Title>
              <Title as="h1">{asset.title}</Title>
              {asset.badges && asset.badges.length > 0 && (
                <BadgeGroup className="margin-bottom-lg">
                  {asset.badges.map(badge => (
                    <Badge key={badge} pill>
                      {badge}
                    </Badge>
                  ))}
                </BadgeGroup>
              )}
            </Col>
            <Col lg={8}>
              <Text size="lg">{asset.description}</Text>
              {((asset.callToAction && asset.callToActionURL) ||
                asset.directDownload) && (
                <ButtonGroup>
                  {asset.callToAction && asset.callToActionURL && (
                    <CallToActionButton
                      disabled={Boolean(asset.callToActionDisabled)}
                      label={asset.callToAction}
                      onClick={() => {
                        setShowModal(true);
                        trackEvent('cta_button', {
                          content_type: 'button',
                          event_category: 'engagement',
                          event_label: asset.callToAction,
                          item_id: asset.id,
                        });
                      }}
                      url={asset.callToActionURL}
                    />
                  )}
                  {asset.directDownload && (
                    <CallToActionButton
                      detectSocial={false}
                      icon="download"
                      label="Direct Download"
                      onClick={() => {
                        setShowModal(true);
                        trackEvent('cta_button', {
                          content_type: 'button',
                          event_category: 'engagement',
                          event_label: 'Direct Download',
                          item_id: asset.id,
                        });
                      }}
                      url={asset.directDownload}
                    />
                  )}
                </ButtonGroup>
              )}
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        <Row>
          <Col className="margin-bottom-lg" xl={8}>
            {asset.screenshots && (
              <div className="margin-bottom-xxl">
                <Title as="h2" marginBottom="none" size={4}>
                  Screenshots
                </Title>
                <ImageGallery
                  className="padding-none"
                  columns={Math.min(asset.screenshots.length, 5)}
                  fullWidthFirstItem={asset.screenshots.length > 2}
                  images={asset.screenshots.map(image => ({
                    width: image.sharp.original.width,
                    height: image.sharp.original.height,
                    src: image.sharp.original.src,
                  }))}
                />
              </div>
            )}
            {asset.callToAction === 'Install from GitHub' && (
              <div className="margin-bottom-lg">
                <Title as="h2" size={4}>
                  Installation
                </Title>
                <Text>
                  Use the Unity Package Manager to install the Tweening package.
                </Text>
                <ol>
                  <li>
                    Open the Package Manager in{' '}
                    <b>Window &#62; Package Manager</b>
                  </li>
                  <li>
                    Click the add (<b>+</b>) button in the status bar
                  </li>
                  <li>
                    Select <b>Add package from git URL</b> from the add menu
                  </li>
                  <li>
                    Enter the following Git URL in the text box and click Add:{' '}
                  </li>
                </ol>
                <code>{`${asset.callToActionURL}.git`}</code>
              </div>
            )}
          </Col>
          <Col className="margin-bottom-lg" xl={4}>
            <DetailsList
              details={asset.details}
              onLinkClick={detail => {
                trackEvent('external_link', {
                  content_type: 'link',
                  event_category: 'engagement',
                  event_label: detail.value,
                  item_id: asset.id,
                });
              }}
            />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export const query = graphql`
  query ($id: String!) {
    asset: assetsJson(id: { eq: $id }) {
      badges
      callToAction
      callToActionURL
      callToActionDisabled
      description
      details {
        key
        url
        value
      }
      directDownload
      id: jsonId
      image {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      screenshots {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      subtitle
      title
    }
  }
`;
